<template>
  <div style="background-color: black" class="page-diy">
    <v-container>
      <div class="DIY-row">
        <div class="wizard-img">
          <v-img
            :src="require('../assets/box-5423439_1920.jpg')"
            class="img-full-left"
          >
          </v-img>
        </div>

        <div class="wizard-body">
          <div class="help-icon" @click="$bvModal.show('dlgHelp')">
            <i class="fa fa-question"></i>
          </div>
          <p class="big-question">REASON FOR EXISTENCE</p>
          <p class="med-question">
            Please discuss the reason(s) why the company came into existence in
            the space provided below:
          </p>
          <b-form-textarea
            v-model="wsData.market_reason"
            clearable
            no-resize
            rows="12"
            max-rows="12"
            class="wizard-input"
          >
          </b-form-textarea>

          <div class="wizard-navigation__wrapper">
            <button class="wizard-navigation" @click="showCBR3">BACK</button>
            <button class="wizard-navigation" @click="showTOC">MENU</button>
            <button class="wizard-navigation" @click="showCBR5">NEXT</button>
          </div>
        </div>
      </div>

      <v-dialog v-if="dlgTOC" v-model="dlgTOC" max-width="600" persistent>
        <v-card style="background-color: whitesmoke">
          <div
            class="outline wizard-header__wrapper"
            style="background-color: blue"
          >
            <p class="wizard-header">Table of Contents</p>
            <span @click="dlgTOC = false"><i class="fa fa-times"></i></span>
          </div>
          <div class="TOC">
            <ul class="mouse-pointer">
              <li>
                <a href="javascript:void(0)" @click="showCBR1"
                  >1. CORPORATE DETAILS</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR2"
                  >2. BUSINESS BIRTH DATE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR3">3. REVENUE</a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR4"
                  >4. REASON FOR EXISTENCE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR5"
                  >5. COMPETITION</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR6"
                  >6. GROWTH POSSIBILITIES</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR7"
                  >7. BUSINESS PROS & CONS</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR8"
                  >8. HOLES TO BE FILLED</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR9"
                  >9. DEFICIENCIES</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR10"
                  >10. CURRENT PIPELINE
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR11"
                  >11. EMPLOYEE NUMBERS</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR12"
                  >12. KEY EMPLOYEES</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR13"
                  >13. CURRENT OWNER ROLE</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR14"
                  >14 NEW OWNER TASKS</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR15"
                  >15 REPLACEMENT COSTS</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showCBR16"
                  >16 KEY ASSETS</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="showWorksheets"
                  >EXIT TO WORKSHEETS</a
                >
              </li>
            </ul>
          </div>
        </v-card>
      </v-dialog>

      <b-modal id="dlgHelp" hide-footer>
        <template #modal-title> DIY HELP </template>
        <div class="d-block text-center">
          <p>.</p>
        </div>
        <b-button
          class="mt-3"
          block
          @click="$bvModal.hide('dlgHelp')"
          style="float: right"
          >Close</b-button
        >
      </b-modal>
    </v-container>
  </div>
</template>

<script>
import CommonServices from "../service/CommonServices";
import { mask } from "vue-the-mask";

export default {
  props: ["returnOnly"],
  components: {},
  directives: {
    mask,
  },

  created() {
    this.getCBRData();

    CommonServices.getItem(this.componentURL_CBR)
      .then((resID) => {
        console.log("Current CBR Response: ", resID);
        console.log("Current CBR ID: ", resID.results[0].id);
        this.getCBRData(resID.results[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      componentURL_CBR: `CBR/?deal_id=${this.$route.params.id}`,
      componentURL_baseCBR: `CBR`,
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogPerks: false,
      dialogSelectUser: false,
      dlgTOC: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      file: "",
      profileImageUrl: "",
      currentDeal: localStorage.getItem("Deal"),
      show: false,
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,
      usCurrency: null,

      companyName: "",

      menuID: localStorage.getItem("menuID"),

      items: [],


      wsData: {
        id: "",
        deal_id: this.$route.params.id,
        last_upload_date: this.$moment(String(Date())).format("MM/DD/YYYY"),
        last_update_user_id: "",
        biz_dob: null,
        corp_details: "",
        revenue: "",
        market_reason: "",
        competition: "",
        growth: null,
        pros_cons: null,
        holes: null,
        deficiencies: "",
        pipeline: null,
        employees: null,
        key_roles: "",
        owner_role: null,
        biz_detail: "",
        replace_costs: null,
        assets_liabilities: "",
        timestamp: "",
        last_updated: null,
      },

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "CBR_1",
        action: "",
        deal_id: "",
        json: "",
      },

      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },

  computed: {},

  methods: {
    checkPermissions() {
      if (this.isAdmin == "false") this.$router.push("/");
    },

    getCBRData(cbrID) {
      CommonServices.Detail(cbrID, this.componentURL_baseCBR)
        .then((res) => {
          console.log("CBR Detail Response: ", res);
          this.wsData = res;
          localStorage.setItem("lastupdate", this.wsData.last_updated);
          this.lastUpdate = this.wsData.last_updated;
          console.log("Last Update: ", this.wsData.last_updated);
          console.log("Last Update Time: ", this.lastUpdateTime);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    saveFormDataCBR() {
      if (this.wsData.last_updated) {
        this.wsData.last_updated = this.$moment(
          String(this.wsData.last_updated)
        ).format("YYYY-MM-DDThh:mm");
      } else {
        this.wsData.last_updated = this.$moment(String(Date())).format(
          "YYYY-MM-DDThh:mm"
        );
      }
      if (this.wsData.id) {
        console.log("saving CBR data", this.wsData);
        CommonServices.updateData(this.componentURL_baseCBR, this.wsData)
          .then((response) => {
            const index = this.items.findIndex(
              (item) => item.id === response.id
            );
            this.$set(this.items, index, response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully updated";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.response.data).forEach((key) => {
                this.snackText = error.response.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      } else {
        console.log("Adding CBR data", this.wsData);
        CommonServices.addRecord(this.componentURL_baseCBR, this.wsData)
          .then((response) => {
            this.items.push(response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully added";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      }
    },

    showCBR1() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_1/${this.$route.params.id}`);
    },

    showCBR2() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_2/${this.$route.params.id}`);
    },

    showCBR3() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_3/${this.$route.params.id}`);
    },

    showCBR4() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_4/${this.$route.params.id}`);
    },

    showCBR5() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_5/${this.$route.params.id}`);
    },

    showCBR6() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_6/${this.$route.params.id}`);
    },

    showCBR7() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_7/${this.$route.params.id}`);
    },

    showCBR8() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_8/${this.$route.params.id}`);
    },

    showCBR9() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_9/${this.$route.params.id}`);
    },

    showCBR10() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_10/${this.$route.params.id}`);
    },

    showCBR11() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_11/${this.$route.params.id}`);
    },

    showCBR12() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_12/${this.$route.params.id}`);
    },

    showCBR13() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_13/${this.$route.params.id}`);
    },

    showCBR14() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_14/${this.$route.params.id}`);
    },

    showCBR15() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_15/${this.$route.params.id}`);
    },

    showCBR16() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/CBR_16/${this.$route.params.id}`);
    },

    showWorksheets() {
      this.dlgTOC = false;
      this.saveFormDataCBR();
      this.$router.push(`/Worksheets/`);
    },

    showTOC() {
      this.dlgTOC = true;
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style src="../css/styles.css" scoped></style>
